import {AbstractComponent, ComponentLoader} from "../../../../AbstractComponent";

declare let $, window, document: any;

export class DownloadOverlay extends AbstractComponent
{
    public static selector: string = "download-overlay";
    public triggerAttribute: string = "download-link";
    public remainOpenOnClickAttr: string = "remain-open-on-click";
    public componentClass: string = "download-overlay";

    init()
    {
        super.init();

        $(document).on("triggerDownloadOverlay", (e) => {
            this.fireDownloadOverlay(e);
        });

        // Add visible class when download link is clicked
        $(document).on('triggerDownloadOverlay click', "[" + this.triggerAttribute + "]", (e) => {
            this.fireDownloadOverlay(e);
        });

        // Remove visible class when overlay is clicked
        if (this.getComponentElement().attr(this.remainOpenOnClickAttr) == undefined)
        {
            this.getComponentElement().on('click', (e) => {
                this.getComponentElement().removeClass(this.componentClass + '--vis');
            });
        }
    }

    fireDownloadOverlay(e)
    {
        e.preventDefault();
        this.triggerDataLink(e);

        // Replace brand name placeholders
        this.getComponentElement().addClass(this.componentClass + '--vis');
    }

    triggerDataLink(vanillaElement)
    {
        let target = '_parent';
        let element = $(vanillaElement.currentTarget);

        // Make sure the data-href attribute exists
        if (typeof element.data('href') !== "undefined")
        {
            window.open(element.data('href'), '_parent');
        }

        if (element.attr('target'))
        {
            target = element.attr('target');
        }

        // Make sure the href attribute exists
        if (typeof element.attr('href') !== "undefined")
        {
            window.open(element.attr('href'), target);
        }
    }
}

new ComponentLoader(DownloadOverlay);
